exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-news-tsx": () => import("./../../../src/pages/news.tsx" /* webpackChunkName: "component---src-pages-news-tsx" */),
  "component---src-pages-page-slug-tsx": () => import("./../../../src/pages/{Page.slug}.tsx" /* webpackChunkName: "component---src-pages-page-slug-tsx" */),
  "component---src-pages-thank-you-tsx": () => import("./../../../src/pages/thank-you.tsx" /* webpackChunkName: "component---src-pages-thank-you-tsx" */),
  "component---src-templates-archive-template-tsx": () => import("./../../../src/templates/archive-template.tsx" /* webpackChunkName: "component---src-templates-archive-template-tsx" */),
  "component---src-templates-artist-template-tsx": () => import("./../../../src/templates/artist-template.tsx" /* webpackChunkName: "component---src-templates-artist-template-tsx" */),
  "component---src-templates-beverage-template-tsx": () => import("./../../../src/templates/beverage-template.tsx" /* webpackChunkName: "component---src-templates-beverage-template-tsx" */),
  "component---src-templates-food-template-tsx": () => import("./../../../src/templates/food-template.tsx" /* webpackChunkName: "component---src-templates-food-template-tsx" */),
  "component---src-templates-music-template-tsx": () => import("./../../../src/templates/music-template.tsx" /* webpackChunkName: "component---src-templates-music-template-tsx" */),
  "component---src-templates-post-template-tsx": () => import("./../../../src/templates/post-template.tsx" /* webpackChunkName: "component---src-templates-post-template-tsx" */),
  "slice---src-components-footer-tsx": () => import("./../../../src/components/footer.tsx" /* webpackChunkName: "slice---src-components-footer-tsx" */),
  "slice---src-components-header-tsx": () => import("./../../../src/components/header.tsx" /* webpackChunkName: "slice---src-components-header-tsx" */)
}

